import { ContentItemInterface } from '@/interfaces/contentItem'
import { AssetRenderer } from '@/components'
import {
  StatisticsContainer,
  StatisticImage,
  StatisticTitle,
  StatisticDescription,
  AssestWrapper,
} from './StatisticsAndIcon.styles'

const StatisticsAndIcon: React.FC<ContentItemInterface> = (props) => {
  const {
    image, imageSize, title, description 
  } = props

  return (
    <StatisticsContainer>
      {image && (
        <AssestWrapper imageSize={imageSize}>
          <AssetRenderer
            asset={image}
            ImageComponent={StatisticImage}
            imageProps={{
              width: 100,
              height: 100,
            }}
          />
        </AssestWrapper>
      )}
      {title && <StatisticTitle>{title}</StatisticTitle>}
      {description && (
        <StatisticDescription>{description}</StatisticDescription>
      )}
    </StatisticsContainer>
  )
}

export default StatisticsAndIcon
